/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import BillingInformation from "layouts/billing/components/BillingInformation";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { baseurl } from "domain";
import axios from "axios";
// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import CageData from "layouts/billing/components/CageActivitiesData";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import { Divider, TextField } from "@mui/material";
import Loading from "components/Loading";

// Date Picker
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

function CageActivities() {
  const { sales, tasks } = reportsLineChartData;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Loading state

  const [userLogsData, setUserLogsData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(format(new Date(), "yyyy-MM-dd"));

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      navigate("/authentication/sign-in");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchUserLogsData = async () => {
      try {
        const response = await axios.get(
          `https://${baseurl}/getAllUserActivityLogs?date_selected=${selectedDate}`
        );
        setUserLogsData(response.data.logs);
        console.log(userLogsData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserLogsData();
  }, []);

  const fetchUserLogsData = async (date) => {
    try {
      const response = await axios.get(
        `https://${baseurl}/getAllUserActivityLogs?date_selected=${date}`
      );
      setUserLogsData(response.data.logs);
      console.log(userLogsData);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading />; // Show loading component if data is still loading
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Card
          sx={{
            position: "relative",
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <MDBox pt={3} px={2}>
            <MDTypography variant="h6" fontWeight="medium">
              Filter By Date
            </MDTypography>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => {
                setSelectedDate(format(date, "yyyy-MM-dd"));
                console.log(format(date, "yyyy-MM-dd"));
                fetchUserLogsData(format(date, "yyyy-MM-dd"));
              }}
              dateFormat="YYYY-MM-dd"
              customInput={<TextField value={format(selectedDate, "yyyy-MM-dd")} />}
            />
          </MDBox>
        </Card>
        <Card
          sx={{
            position: "relative",
            mx: 3,
            py: 2,
            px: 2,
            mt: 3,
          }}
        >
          <MDBox pt={3} px={2}>
            <MDTypography variant="h6" fontWeight="medium">
              Cage Activities
            </MDTypography>
          </MDBox>
          <MDBox pt={1} pb={2} px={2}>
            <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
              {userLogsData.map((log, index) => (
                <>
                  {log.lType === "userActivityLog" && (
                    <CageData
                      key={index}
                      name={log.lText}
                      uname={log.name}
                      company={log.loc_name}
                      vat={log.date}
                      time={log.time}
                      lat={log.lat}
                      lng={log.lng}
                      cageimg={log.currentImg}
                      csrno={log.srNo}
                    />
                  )}
                </>
              ))}
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CageActivities;
