import Card from "@mui/material/Card";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseurl } from "domain";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import {
  TextField,
  Button,
  Grid,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import authorsTableData from "layouts/tables/data/authorsTableData";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

function Tables() {
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    firstName: "",
    lastName: "",
    username: "",
    designation: "",
    range: "",
  });
  const [tableData, setTableData] = useState({ columns: [], rows: [] });

  const handleClickView = (uid) => {
    navigate(`/profile?uid=${uid}`);
  };

  const handleClickDelete = async (uid) => {
    if (confirm("Do you really want to delete this User ?")) {
      try {
        const response = await axios.delete(`https://${baseurl}/deleteUser?uid=${uid}`);
        if (response.status === 200) {
          alert("User Deleted Successfully!");
          setTableData((prevData) => ({
            ...prevData,
            rows: prevData.rows.filter((row) => row.uid !== uid),
          }));
        } else {
          alert("Try Again");
        }
      } catch (error) {
        alert("Error Deleting User");
        console.error("Error deleting user:", error);
      }
    }
  };

  const handleEditClick = (uid) => {
    navigate(`/profileEdit?uid=${uid}`);
  };

  // Fetch table data
  const fetchTableData = async () => {
    const result = await authorsTableData(
      filters,
      handleClickView,
      handleClickDelete,
      handleEditClick
    );
    setTableData(result);
  };

  // Fetch table data whenever filters change
  useEffect(() => {
    fetchTableData();
  }, [filters]);

  // Redirect if no token
  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      navigate("/authentication/sign-in");
    }
  }, [navigate]);

  // Handle filter change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // Handle filter clear
  const handleClearFilters = async () => {
    // Reset filters
    setFilters({
      firstName: "",
      lastName: "",
      username: "",
      designation: "",
      range: "",
    });
    // Fetch table data with cleared filters
    fetchTableData();
  };

  // Handle add user click
  const handleClick = () => {
    navigate("/AddUser");
  };

  // Handle download functionality
  const handleDownload = () => {
    const keysToExclude = ["uid"];
    const transformedData = tableData.rows.map((row) => {
      const newItem = { ...row };
      keysToExclude.forEach((key) => delete newItem[key]);
      return newItem;
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "data.xlsx");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                  <Grid item>
                    <MDTypography variant="h6" color="white">
                      User Details
                    </MDTypography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="white"
                      onClick={handleClick}
                      style={{ backgroundColor: "white", color: "#0076CE" }}
                    >
                      + Add User
                    </Button>
                  </Grid>
                </Grid>
              </MDBox>
              <Grid container spacing={2} padding={3} alignItems="center">
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    name="firstName"
                    value={filters.firstName}
                    onChange={handleChange}
                    label="First Name"
                    style={{ height: 40 }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    name="lastName"
                    value={filters.lastName}
                    onChange={handleChange}
                    label="Last Name"
                    style={{ height: 40 }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    name="username"
                    value={filters.username}
                    onChange={handleChange}
                    label="Username"
                    style={{ height: 40 }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Box>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Designation</InputLabel>
                      <Select
                        name="designation"
                        label="Designation"
                        onChange={handleChange}
                        value={filters.designation}
                        style={{ padding: 12 }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="DyCF">DyCF</MenuItem>
                        <MenuItem value="ACF">ACF</MenuItem>
                        <MenuItem value="RFO">RFO</MenuItem>
                        <MenuItem value="Forester">Forester</MenuItem>
                        <MenuItem value="Forest Guard">Forest Guard</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Box>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Range</InputLabel>
                      <Select
                        name="range"
                        value={filters.range}
                        label="Range"
                        onChange={handleChange}
                        style={{ padding: 12 }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="Junnar">Junnar</MenuItem>
                        <MenuItem value="Ghodegaon">Ghodegaon</MenuItem>
                        <MenuItem value="Chakan">Chakan</MenuItem>
                        <MenuItem value="Shirur">Shirur</MenuItem>
                        <MenuItem value="Otur">Otur</MenuItem>
                        <MenuItem value="Khed">Khed</MenuItem>
                        <MenuItem value="Manchar">Manchar</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={handleClearFilters}
                    style={{ color: "white" }}
                  >
                    Clear
                  </Button>
                </Grid>
                {/* <Grid item xs={12} sm={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDownload}
                    style={{ color: 'white' }}
                  >
                    Download
                  </Button>
                </Grid> */}
              </Grid>
              <MDBox mx={2} pt={3}>
                <DataTable
                  table={{ columns: tableData.columns, rows: tableData.rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
