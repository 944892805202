/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  Box,
  Grid,
  Card,
  Icon,
  Button,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MapIcon from "@mui/icons-material/Map";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import axios from "axios"; // Import Axios
import { baseurl } from "domain";

import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

function Notifications() {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status");
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    locName: "",
    region: "",
    srNo: "",
    status: status,
  });

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      // Redirect to sign-in page if token is not present
      navigate("/authentication/sign-in");
    }

    const fetchData = async () => {
      try {
        const response = await axios.get(`https://${baseurl}/getAllCages`);
        setData(response.data.cages);
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      try {
        const response = await axios.post(`https://${baseurl}/filterCages`, filters);
        setData(response.data.cages);
      } catch (error) {
        console.error("Error fetching filtered data:", error);
      }
    };

    fetchData();
  }, [navigate]);

  const handleChange = async (event) => {
    const { name, value } = event.target;
    const updatedFilters = {
      ...filters,
      [name]: value,
    };
    setFilters(updatedFilters);

    try {
      const response = await axios.post(`https://${baseurl}/filterCages`, updatedFilters);
      setData(response.data.cages);
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
  };

  const handleClickView = (cid) => {
    navigate(`/CageProfile?cid=${cid}`);
  };

  const handleClickDelete = async (cid) => {
    if (window.confirm("Do you really want to delete this Cage ?")) {
      try {
        const response = await axios.delete(`https://${baseurl}/deleteCage?cid=${cid}`);
        if (response.status === 200) {
          alert("Cage Deleted Successfully!");
          setData((prevData) => prevData.filter((item) => item.cid !== cid));
        } else {
          alert("Try Again");
        }
      } catch (error) {
        alert("Error Deleting Cage");
        console.error("Error deleting data:", error);
      }
    }
  };

  const handleEditClick = (cid) => {
    navigate(`/CageProfileEdit?cid=${cid}`);
  };

  const excludeKeys = (data, keysToExclude) => {
    return data.map((item) => {
      let newItem = {};
      Object.keys(item).forEach((key) => {
        if (!keysToExclude.includes(key)) {
          newItem[key] = item[key];
        }
      });
      return newItem;
    });
  };

  if (localStorage.getItem("designation") != "Forester") {
    var columns = [
      { Header: "Sr.no", accessor: "srNo", align: "center" },
      // { Header: "Current Location", accessor: "Id", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "Location", accessor: "Location", align: "center" },
      // { Header: "Region", accessor: "Region", align: "center" },
      { Header: "Range", accessor: "Range", align: "center" },

      { Header: "Map", accessor: "curr_loc", align: "center" },
      { Header: "Edit", accessor: "Edit", align: "center" },
      { Header: "View", accessor: "View", align: "center" },
      { Header: "Delete", accessor: "Delete", align: "center" },
    ];
  } else {
    var columns = [
      { Header: "Sr.no", accessor: "srNo", align: "center" },
      // { Header: "Current Location", accessor: "Id", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "Location", accessor: "Location", align: "center" },
      // { Header: "Region", accessor: "Region", align: "center" },
      { Header: "Range", accessor: "Range", align: "center" },

      { Header: "Map", accessor: "curr_loc", align: "center" },
      // { Header: "Edit", accessor: "Edit", align: "center" },
      { Header: "View", accessor: "View", align: "center" },
      // { Header: "Delete", accessor: "Delete", align: "center" },
    ];
  }

  const rows = data.map((cage) => ({
    srNo: (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDBox lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {cage.srNo}
          </MDTypography>
          <MDTypography variant="caption">{cage.email}</MDTypography>
        </MDBox>
      </MDBox>
    ),
    status: (
      <MDBox ml={-1}>
        {cage.status === "active" && (
          <MDBadge badgeContent={cage.status} color="success" variant="gradient" size="sm" />
        )}
        {cage.status === "camp-cage" && (
          <MDBadge badgeContent={cage.status} color="error" variant="gradient" size="sm" />
        )}
        {cage.status === "maintenance" && (
          <MDBadge badgeContent={cage.status} color="warning" variant="gradient" size="sm" />
        )}
      </MDBox>
    ),
    Location: (
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {cage.locName}
      </MDTypography>
    ),
    Region: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {cage.region}
      </MDTypography>
    ),
    Range: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {cage.range}
      </MDTypography>
    ),
    curr_loc: (
      <MDButton
        variant="text"
        color="error"
        onClick={() => {
          const url = `https://www.google.com/maps?q=${cage.lat},${cage.lng}`;
          window.open(url, "_blank", "noopener,noreferrer");
        }}
      >
        <MapIcon /> &nbsp;Map
      </MDButton>
    ),
    View: (
      <MDButton
        variant="text"
        color="primary"
        style={{ color: "blue" }}
        onClick={() => handleClickView(cage.cid)}
      >
        <VisibilityIcon style={{ color: "blue" }} />
        &nbsp;view
      </MDButton>
    ),
    Delete: (
      <MDButton variant="text" color="error" onClick={() => handleClickDelete(cage.cid)}>
        <Icon>delete</Icon>&nbsp;delete
      </MDButton>
    ),
    Edit: (
      <MDButton variant="text" color="dark" onClick={() => handleEditClick(cage.cid)}>
        <Icon>edit</Icon>&nbsp;edit
      </MDButton>
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                  <Grid item>
                    <MDTypography variant="h6" color="white">
                      Reports
                    </MDTypography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="white"
                      onClick={() => {
                        const keysToExclude = ["cid"];

                        // Transform data by excluding specific keys
                        const transformedData = excludeKeys(data, keysToExclude);

                        // Create a new workbook
                        const workbook = XLSX.utils.book_new();

                        // Convert JSON data to worksheet
                        const worksheet = XLSX.utils.json_to_sheet(transformedData);

                        // Append worksheet to workbook
                        XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

                        // Write the workbook to a buffer
                        const excelBuffer = XLSX.write(workbook, {
                          bookType: "xlsx",
                          type: "array",
                        });

                        // Create a Blob object from the buffer
                        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

                        // Save the Blob as an Excel file
                        saveAs(blob, "data.xlsx");
                      }}
                      style={{ color: "primary" }}
                    >
                      <Icon fontSize="small">downloads</Icon>&nbsp; Download
                    </Button>
                  </Grid>
                </Grid>
              </MDBox>
              <Grid container spacing={2} padding={3} alignItems="center">
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    name="locName"
                    value={filters.locName}
                    onChange={handleChange}
                    label="Location"
                    style={{ height: 40 }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Region</InputLabel>
                    <Select
                      name="region"
                      value={filters.region}
                      onChange={handleChange}
                      label="Region"
                      style={{ height: 40 }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="Junnar">Junnar</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    fullWidth
                    name="srNo"
                    value={filters.srNo}
                    onChange={handleChange}
                    label="Serial No"
                    style={{ height: 40 }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Box>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel>Range</InputLabel>
                      <Select
                        name="range"
                        value={filters.range}
                        label="Range"
                        onChange={handleChange}
                        style={{ padding: 12 }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"Junnar"}>Junnar</MenuItem>
                        <MenuItem value={"Ghodegaon"}>Ghodegaon</MenuItem>
                        <MenuItem value={"Chakan"}>Chakan</MenuItem>
                        <MenuItem value={"Shirur"}>Shirur</MenuItem>
                        <MenuItem value={"Otur"}>Otur</MenuItem>
                        <MenuItem value={"Khed"}>Khed</MenuItem>
                        <MenuItem value={"Manchar"}>Manchar</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Status</InputLabel>
                    <Select
                      name="status"
                      value={filters.status}
                      onChange={handleChange}
                      label="Status"
                      style={{ height: 40 }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="active">Active</MenuItem>
                      <MenuItem value="camp-cage">Camp Cage</MenuItem>
                      <MenuItem value="maintenance">Maintenance</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={async () => {
                      setFilters({
                        locName: "",
                        region: "",
                        srNo: "",
                        status: "",
                      });
                      try {
                        const response = await axios.post(`https://${baseurl}/filterCages`, {});
                        setData(response.data.cages);
                      } catch (error) {
                        console.error("Error fetching filtered data:", error);
                      }
                    }}
                    style={{ color: "white" }}
                  >
                    Clear
                  </Button>
                </Grid>
                {/* <Grid item xs={12} sm={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      const keysToExclude = ['cid'];

                      // Transform data by excluding specific keys
                      const transformedData = excludeKeys(data, keysToExclude);

                      // Create a new workbook
                      const workbook = XLSX.utils.book_new();

                      // Convert JSON data to worksheet
                      const worksheet = XLSX.utils.json_to_sheet(transformedData);

                      // Append worksheet to workbook
                      XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

                      // Write the workbook to a buffer
                      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

                      // Create a Blob object from the buffer
                      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

                      // Save the Blob as an Excel file
                      saveAs(blob, 'data.xlsx');
                    }}
                    style={{ color: 'white' }}

                  >
                    <Icon fontSize="small">downloads</Icon>
                  </Button>
                </Grid> */}
              </Grid>
              <MDBox mx={2} pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Notifications;
