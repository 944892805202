// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios"; // Import Axios
import { baseurl } from "domain";
import MapIcon from "@mui/icons-material/Map";

export default function data() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://${baseurl}/getAllActionsLogs`);
        setData(response.data.logs);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const columns = [
    { Header: "Date", accessor: "date", align: "center" },
    { Header: "Status", accessor: "status", align: "center" },
    // { Header: "Designation", accessor: "designation", align: "center" },
    { Header: "User", accessor: "user", align: "center" },
    { Header: "Cages", accessor: "cages", align: "center" },
    { Header: "Range", accessor: "range", align: "center" },
    { Header: "Assigned By", accessor: "assignedBy", align: "center" },
    { Header: "Action By", accessor: "actionBy", align: "center" },
  ];

  const filteredLogs = data.filter(
    (log) => log.lType === "cageAssignedUser" || log.lType === "cageAssignedRemoveUser"
  );

  const rows = filteredLogs.map((log, index) => {
    let statusContent;

    if (log.lType === "cageAssignedUser") {
      statusContent = "Approved";
    } else {
      statusContent = "Rejected";
    }

    return {
      date: log.date,
      status: (
        <MDBox ml={-1}>
          {statusContent === "Approved" && (
            <MDBadge badgeContent={statusContent} color="success" variant="gradient" size="sm" />
          )}
          {statusContent === "Rejected" && (
            <MDBadge badgeContent={statusContent} color="error" variant="gradient" size="sm" />
          )}
        </MDBox>
      ),
      // designation: log.designation,
      user: log.name,
      cages: log.cageText,
      range: log.range,
      assignedBy: log.editBy,
      actionBy: log.actionBy,
    };
  });

  return { columns, rows };
}
