// /**
// =========================================================
// * Material Dashboard 2 React - v2.2.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/material-dashboard-react
// * Copyright 2023 Creative Tim (https://www.creative-tim.com)

// Coded by www.creative-tim.com

//  =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
// */

// import { useState, useEffect } from "react";

// // react-router components
// import { useLocation, Link } from "react-router-dom";

// // prop-types is a library for typechecking of props.
// import PropTypes from "prop-types";

// // @material-ui core components
// import AppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";
// import IconButton from "@mui/material/IconButton";
// import Menu from "@mui/material/Menu";
// import Icon from "@mui/material/Icon";
// import brand from "assets/images/logo-ct.png";
// // Material Dashboard 2 React components
// import MDBox from "components/MDBox";
// import MDInput from "components/MDInput";
// import CustomPopup from "./notification";
// // Material Dashboard 2 React example components
// import Breadcrumbs from "examples/Breadcrumbs";
// import NotificationItem from "examples/Items/NotificationItem";
// import Button from '@mui/material/Button';

// // Custom styles for DashboardNavbar
// import {
//   navbar,
//   navbarContainer,
//   navbarRow,
//   navbarIconButton,
//   navbarMobileMenu,
// } from "examples/Navbars/DashboardNavbar/styles";

// // Material Dashboard 2 React context
// import {
//   useMaterialUIController,
//   setTransparentNavbar,
//   setMiniSidenav,
//   setOpenConfigurator,
// } from "context";

// import { baseurl } from "domain";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";

// function DashboardNavbar({ absolute, light, isMini }) {
//   const [navbarType, setNavbarType] = useState();
//   const [controller, dispatch] = useMaterialUIController();
//   const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
//   const [openMenu, setOpenMenu] = useState(false);
//   const route = useLocation().pathname.split("/").slice(1);

//   const [texts, setTexts] = useState([]);
//   const [open, setOpen] = useState(false);

//   const fetchNotifications = async () => {
//     try {
//       const response = await axios.get(`https://${baseurl}/getAllNotifications`);
//       setTexts(response.data.notifications);
//     } catch (error) {
//       console.error("Error fetching notifications:", error);
//     }
//   };

//   useEffect(() => {
//     // Setting the navbar type
//     if (fixedNavbar) {
//       setNavbarType("sticky");
//     } else {
//       setNavbarType("static");
//     }

//     // A function that sets the transparent state of the navbar.
//     function handleTransparentNavbar() {
//       setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
//     }

//     /**
//      The event listener that's calling the handleTransparentNavbar function when
//      scrolling the window.
//     */
//     window.addEventListener("scroll", handleTransparentNavbar);

//     // Call the handleTransparentNavbar function to set the state with the initial value.
//     handleTransparentNavbar();

//     // Remove event listener on cleanup
//     return () => window.removeEventListener("scroll", handleTransparentNavbar);
//   }, [dispatch, fixedNavbar]);

//   useEffect(() => {
//     fetchNotifications();
//     const interval = setInterval(fetchNotifications, 10000); // Fetch notifications every 60 seconds
//     return () => clearInterval(interval);
//   }, []);

//   const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
//   const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
//   const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
//   const handleCloseMenu = () => setOpenMenu(false);

//   const handleOpen = () => {
//     fetchNotifications();
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   // Render the notifications menu
//   const renderMenu = () => (
//     <Menu
//       anchorEl={openMenu}
//       anchorReference={null}
//       anchorOrigin={{
//         vertical: "bottom",
//         horizontal: "left",
//       }}
//       open={Boolean(openMenu)}
//       onClose={handleCloseMenu}
//       sx={{ mt: 2 }}
//     >
//       <NotificationItem icon={<Icon>account_circle</Icon>} title="log-out" />
//     </Menu>
//   );

//   // Styles for the navbar icons
//   const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
//     color: () => {
//       let colorValue = light || darkMode ? white.main : dark.main;

//       if (transparentNavbar && !light) {
//         colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
//       }

//       return colorValue;
//     },
//   });

//   return (
//     <AppBar
//       position={absolute ? "absolute" : navbarType}
//       color="inherit"
//       sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
//     >
//       <Toolbar sx={(theme) => navbarContainer(theme)}>
//         <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
//           <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
//         </MDBox>
//         {isMini ? null : (
//           <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
//             <MDBox color={light ? "white" : "inherit"}>
//               <IconButton
//                 size="small"
//                 disableRipple
//                 color="inherit"
//                 sx={navbarIconButton}
//                 aria-controls="notification-menu"
//                 aria-haspopup="true"
//                 variant="contained"
//                 onClick={handleOpenMenu}
//               >
//                 <Icon sx={iconsStyle}>account_circle</Icon>
//               </IconButton>
//               <IconButton
//                 size="small"
//                 disableRipple
//                 color="inherit"
//                 sx={navbarIconButton}
//                 aria-controls="notification-menu"
//                 aria-haspopup="true"
//                 variant="contained"
//                 onClick={handleOpen}
//               >
//                 <Icon sx={iconsStyle}>{texts.length > 0 ? "notifications_active" : "notifications"}</Icon>
//               </IconButton>
//               <CustomPopup
//                 open={open}
//                 onClose={handleClose}
//               />
//               <IconButton
//                 size="small"
//                 disableRipple
//                 color="inherit"
//                 sx={navbarMobileMenu}
//                 onClick={handleMiniSidenav}
//               >
//                 <Icon sx={iconsStyle} fontSize="medium">
//                   {miniSidenav ? "menu_open" : "menu"}
//                 </Icon>
//               </IconButton>
//               <IconButton
//                 size="small"
//                 disableRipple
//                 color="inherit"
//                 sx={navbarIconButton}
//                 onClick={handleConfiguratorOpen}
//               >
//                 <Icon sx={iconsStyle}>settings</Icon>
//               </IconButton>
//               {renderMenu()}
//             </MDBox>
//             <MDBox component="img" src={brand} alt="Brand" width="4rem" />
//           </MDBox>
//         )}
//       </Toolbar>
//     </AppBar>
//   );
// }

// // Setting default values for the props of DashboardNavbar
// DashboardNavbar.defaultProps = {
//   absolute: false,
//   light: false,
//   isMini: false,
// };

// // Typechecking props for the DashboardNavbar
// DashboardNavbar.propTypes = {
//   absolute: PropTypes.bool,
//   light: PropTypes.bool,
//   isMini: PropTypes.bool,
// };

// export default DashboardNavbar;

/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import brand from "assets/images/logo-ct.png";

import MDTypography from "components/MDTypography";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import CustomPopup from "./notification";
// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import Button from "@mui/material/Button";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

import { baseurl } from "domain";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const name1 = localStorage.getItem("name");
  const [texts, setTexts] = useState([]);
  const [open, setOpen] = useState(false);
  const intervalRef = useRef(null);

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`https://${baseurl}/getAllNotifications`);
      setTexts(response.data.notifications);
      if (response.data.notifications.length === 0 && intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const startPolling = () => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(fetchNotifications, 10000); // Poll every 10 seconds
    }
  };

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      // setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  useEffect(() => {
    fetchNotifications();
    startPolling();
    return () => clearInterval(intervalRef.current);
  }, []);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const handleOpen = () => {
    fetchNotifications();
    setOpen(true);
    startPolling();
  };

  const handleClose = () => {
    setOpen(false);
    if (texts.length === 0) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>account_circle</Icon>} title="log-out" />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox sx={{ mr: 2 }} color={light ? "white" : "inherit"}>
              <MDTypography variant="button" fontWeight="bold" color="black">
                {name1}
              </MDTypography>

              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon sx={iconsStyle}>account_circle</Icon>
              </IconButton>

              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpen}
              >
                {localStorage.getItem("designation") == "DyCF" && (
                  <Icon sx={iconsStyle}>
                    {texts.length > 0 ? "notifications_active" : "notifications"}
                  </Icon>
                )}
                {localStorage.getItem("designation") == "ACF" && (
                  <Icon sx={iconsStyle}>
                    {texts.length > 0 ? "notifications_active" : "notifications"}
                  </Icon>
                )}

                {localStorage.getItem("designation") === "RFO" && (
                  <Icon sx={iconsStyle}>
                    {texts.some((item) => item.range === localStorage.getItem("range"))
                      ? "notifications_active"
                      : "notifications"}
                  </Icon>
                )}
                {localStorage.getItem("designation") == "system" && (
                  <Icon sx={iconsStyle}>
                    {texts.length > 0 ? "notifications_active" : "notifications"}
                  </Icon>
                )}
              </IconButton>
              <CustomPopup open={open} onClose={handleClose} />
              <IconButton size="small" disableRipple color="inherit" onClick={handleMiniSidenav}>
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              {/* <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton> */}

              {renderMenu()}
            </MDBox>

            <MDBox component="img" src={brand} alt="Brand" width="4rem" />
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;

// import { useState, useEffect, useRef } from "react";
// import { useLocation } from "react-router-dom";
// import PropTypes from "prop-types";
// import AppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";
// import IconButton from "@mui/material/IconButton";
// import Icon from "@mui/material/Icon";
// import Menu from "@mui/material/Menu";
// import Button from '@mui/material/Button';
// import brand from "assets/images/logo-ct.png";
// import MDBox from "components/MDBox";
// import Breadcrumbs from "examples/Breadcrumbs";
// import NotificationItem from "examples/Items/NotificationItem";
// import CustomPopup from "./notification";
// import {
//   navbar,
//   navbarContainer,
//   navbarRow,
//   navbarIconButton,
//   navbarMobileMenu,
// } from "examples/Navbars/DashboardNavbar/styles";
// import {
//   useMaterialUIController,
//   setTransparentNavbar,
//   setMiniSidenav,
//   setOpenConfigurator,
// } from "context";
// import { baseurl } from "domain";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";

// function DashboardNavbar({ absolute, light, isMini }) {
//   const [navbarType, setNavbarType] = useState();
//   const [controller, dispatch] = useMaterialUIController();
//   const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
//   const [openMenu, setOpenMenu] = useState(false);
//   const route = useLocation().pathname.split("/").slice(1);
//   const [texts, setTexts] = useState([]);
//   const [open, setOpen] = useState(false);
//   const intervalRef = useRef(null);
//   const zoomLevel = useRef(1);

//   const fetchNotifications = async () => {
//     try {
//       const response = await axios.get(`https://${baseurl}/getAllNotifications`);
//       setTexts(response.data.notifications);
//       if (response.data.notifications.length === 0 && intervalRef.current) {
//         clearInterval(intervalRef.current);
//         intervalRef.current = null;
//       }
//     } catch (error) {
//       console.error("Error fetching notifications:", error);
//     }
//   };

//   const startPolling = () => {
//     if (!intervalRef.current) {
//       intervalRef.current = setInterval(fetchNotifications, 10000);
//     }
//   };

//   useEffect(() => {
//     if (fixedNavbar) {
//       setNavbarType("sticky");
//     } else {
//       setNavbarType("static");
//     }

//     function handleTransparentNavbar() {
//       // setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
//     }

//     window.addEventListener("scroll", handleTransparentNavbar);
//     handleTransparentNavbar();
//     return () => window.removeEventListener("scroll", handleTransparentNavbar);
//   }, [dispatch, fixedNavbar]);

//   useEffect(() => {
//     fetchNotifications();
//     startPolling();
//     return () => clearInterval(intervalRef.current);
//   }, []);

//   const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
//   const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
//   const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
//   const handleCloseMenu = () => setOpenMenu(false);

//   const handleOpen = () => {
//     fetchNotifications();
//     setOpen(true);
//     startPolling();
//   };

//   const handleClose = () => {
//     setOpen(false);
//     if (texts.length === 0) {
//       clearInterval(intervalRef.current);
//       intervalRef.current = null;
//     }
//   };

//   const zoomIn = () => {
//     zoomLevel.current += 0.1;
//     document.body.style.zoom = zoomLevel.current;
//   };

//   const zoomOut = () => {
//     zoomLevel.current = Math.max(0.1, zoomLevel.current - 0.1);
//     document.body.style.zoom = zoomLevel.current;
//   };

//   const renderMenu = () => (
//     <Menu
//       anchorEl={openMenu}
//       anchorReference={null}
//       anchorOrigin={{
//         vertical: "bottom",
//         horizontal: "left",
//       }}
//       open={Boolean(openMenu)}
//       onClose={handleCloseMenu}
//       sx={{ mt: 2 }}
//     >
//       <NotificationItem icon={<Icon>account_circle</Icon>} title="log-out" />
//     </Menu>
//   );

//   const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
//     color: () => {
//       let colorValue = light || darkMode ? white.main : dark.main;
//       if (transparentNavbar && !light) {
//         colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
//       }
//       return colorValue;
//     },
//   });

//   return (
//     <AppBar
//       position={absolute ? "absolute" : navbarType}
//       color="inherit"
//       sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
//     >
//       <Toolbar sx={(theme) => navbarContainer(theme)}>
//         <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
//           <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
//         </MDBox>
//         {isMini ? null : (
//           <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
//             <MDBox sx={{ mr: 2 }} color={light ? "white" : "inherit"}>
//               <IconButton
//                 size="small"
//                 disableRipple
//                 color="inherit"
//                 sx={navbarIconButton}
//                 aria-controls="notification-menu"
//                 aria-haspopup="true"
//                 variant="contained"
//                 onClick={handleOpenMenu}
//               >
//                 <Icon sx={iconsStyle}>account_circle</Icon>
//               </IconButton>
//               <IconButton
//                 size="small"
//                 disableRipple
//                 color="inherit"
//                 sx={navbarIconButton}
//                 aria-controls="notification-menu"
//                 aria-haspopup="true"
//                 variant="contained"
//                 onClick={handleOpen}
//               >
//                 {localStorage.getItem("designation") === "DyCF" && (
//                   <Icon sx={iconsStyle}>{texts.length > 0 ? "notifications_active" : "notifications"}</Icon>
//                 )}
//                 {localStorage.getItem("designation") === "ACF" && (
//                   <Icon sx={iconsStyle}>{texts.length > 0 ? "notifications_active" : "notifications"}</Icon>
//                 )}
//               </IconButton>
//               <CustomPopup open={open} onClose={handleClose} />
//               <IconButton
//                 size="small"
//                 disableRipple
//                 color="inherit"
//                 onClick={handleMiniSidenav}
//               >
//                 <Icon sx={iconsStyle} fontSize="medium">
//                   {miniSidenav ? "menu_open" : "menu"}
//                 </Icon>
//               </IconButton>
//               {renderMenu()}
//             </MDBox>
//             <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
//               <Button onClick={zoomOut} variant="contained" color="primary" size="small" sx={{ marginRight: 1 }}>
//                 Zoom Out
//               </Button>
//               <Button onClick={zoomIn} variant="contained" color="primary" size="small">
//                 Zoom In
//               </Button>
//             </MDBox>
//             <MDBox component="img" src={brand} alt="Brand" width="4rem" />
//           </MDBox>
//         )}
//       </Toolbar>
//     </AppBar>
//   );
// }

// DashboardNavbar.defaultProps = {
//   absolute: false,
//   light: false,
//   isMini: false,
// };

// DashboardNavbar.propTypes = {
//   absolute: PropTypes.bool,
//   light: PropTypes.bool,
//   isMini: PropTypes.bool,
// };

// export default DashboardNavbar;
