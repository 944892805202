/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MuiLink from "@mui/material/Link";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import CageInfoCard from "examples/Cards/InfoCards/CageInfoCard";

// Overview page components
import Header from "layouts/CageProfile/components/Header";
import Bill from "layouts/billing/components/CageAssignment";
import Card from "@mui/material/Card";
import { useState, useEffect } from "react";
import axios from "axios"; // Import Axios
import { baseurl } from "domain";
import cageImage from "./cage-image.jpg";
import Loading from "components/Loading";
function Overview() {
  const [cageData, setCageData] = useState({});
  const [cageLogsData, setCageLogsData] = useState([]);
  // const [loading, setLoading] = useState(true); // Loading state

  const queryParams = new URLSearchParams(location.search);
  // const question_id = queryParams.get("id");
  const cid = queryParams.get("cid");

  useEffect(() => {
    const fetchCageData = async () => {
      try {
        const response = await axios.get(`https://${baseurl}/getCage?cid=${cid}`);
        setCageData(response.data.cage);
      } catch (error) {
        console.error("Error fetching cage data:", error);
      }
    };

    fetchCageData();
  }, [cid]);

  // if (loading) {
  //   return <Loading />; // Show loading component if data is still loading
  // }

  useEffect(() => {
    const fetchCageLogsData = async () => {
      try {
        const response = await axios.get(`https://${baseurl}/getCageLogs?cid=${cid}`);
        setCageLogsData(response.data.logs);
        console.log(userLogsData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchCageLogsData();
  }, [cid]);

  const lat = cageData.lat;
  const lng = cageData.lng;
  const region = cageData.region;
  const srNo = cageData.srNo;
  const status = cageData.status;
  const locName = cageData.locName;
  const currentImg = cageData.currentImg;

  const mapUrl2 =
    "https://www.google.com/maps/embed/v1/place?q=Pune,India&key=YOUR_GOOGLE_MAPS_API_KEY";

  const mapUrl = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d122974.02448821405!2d${lng}!3d${lat}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c0655f8f272f%3A0x29564d0a7b6f4a0d!2s${locName}%2C%20Maharashtra%2C%20India!5e0!3m2!1sen!2sus!4v1631493424034!5m2!1sen!2sus`;

  const currUrl = `https://www.google.com/maps?q=${lat},${lng}`;
  console.log(mapUrl);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          {/* <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <CageInfoCard
                title="Cage information"
                description="Placed in Farm. Animal Spotted Near Tree as per Farm Owner's Information."
                info={{
                  Serial_no: [srNo],
                  Status: [status],
                  Cordinates: [lat," , ", lng],
                  Region: [region],
                  Location: [cageData.locName],
                }}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>

            <Grid item xs={12} md={6} xl={8} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 4 }} />
              <iframe
                width="100%"
                height="100%"
                style={{ border: 0 }}
                src={mapUrl}
                allowFullScreen
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </Grid>
            <Grid>

            </Grid> */}
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <CageInfoCard
                title="Cage information"
                // description="Placed in Farm. Animal Spotted Near Tree as per Farm Owner's Information."
                info={{
                  Serial_no: [srNo],
                  Status: [status],
                  Cordinates: [lat, " , ", lng],
                  Region: [region],
                  Location: [cageData.locName],
                }}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>

            {/* New Grid item for the image */}
            <Grid
              item
              xs={12}
              md={6}
              xl={4}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              {!currentImg && (
                <img
                loading="lazy"
                  src={cageImage}
                  alt="Description"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              )}
              {currentImg && (
                <img
                loading="lazy"
                  src={currentImg}
                  alt="Description"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              )}
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>

            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 4 }} />
              <iframe
                width="100%"
                height="100%"
                style={{ border: 0 }}
                src={mapUrl}
                allowFullScreen
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems="center" sx={{ marginTop: "32px" }}>
            <MuiLink href={currUrl} target="_blank" rel="noreferrer">
              <MDButton color={"dark"}>{"Open in Google Maps"}</MDButton>
            </MuiLink>
          </Grid>
        </MDBox>
      </Header>
      <MDBox mb={2} />

      <Card
        sx={{
          position: "relative",
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <MDBox pt={3} px={2}>
          <MDTypography variant="h6" fontWeight="medium">
            Logs
          </MDTypography>
        </MDBox>
        <MDBox pt={1} pb={2} px={2}>
          <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
            {cageLogsData.map((log, index) => (
              <>
                {log.lType == "cageAssignment" && (
                  <Bill
                    key={index}
                    name={log.lText}
                    // company={log.name}
                    email={log.range}
                    vat={log.date}
                  />
                )}
              </>
            ))}
          </MDBox>
        </MDBox>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
