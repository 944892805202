/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MapIcon from "@mui/icons-material/Map";
// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { Grid } from "@mui/material";

function Bill({ name, uname, company, vat, time, lat, lng, cageimg, csrno, noGutter }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const navigate = useNavigate();

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "#F2F2F2"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <Grid container spacing={1} justifyContent="space-between">
          <Grid
            item
            xs={12}
            md={8}
            xl={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              minWidth: "50%",
            }}
          >
            <MDBox
              display="flex"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                {name}
              </MDTypography>
            </MDBox>
            <Divider />
            <MDBox mb={1} lineHeight={0}>
              <MDTypography variant="button" color="text">
                SR No: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <MDTypography variant="button" fontWeight="medium">
                  {csrno}
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox mb={1} lineHeight={0}>
              <MDTypography variant="button" color="text">
                Updated By:&nbsp;&nbsp;&nbsp;
                <MDTypography variant="button" fontWeight="medium">
                  {uname}
                </MDTypography>
              </MDTypography>
            </MDBox>

            <MDBox mb={1} lineHeight={0}>
              <MDTypography variant="button" color="text">
                Loc Name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                  {company}
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox mb={1} lineHeight={0}>
              <MDTypography variant="button" color="text">
                Time:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <MDTypography variant="button" fontWeight="medium">
                  {time}
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox mb={1} lineHeight={0}>
              <MDTypography variant="button" color="text">
                Date:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <MDTypography variant="button" fontWeight="medium">
                  {vat}
                </MDTypography>
              </MDTypography>
            </MDBox>

            <MDButton
              variant="text"
              sx={{
                color: "#ffffff",
                backgroundColor: "#3892ee",
                "&:hover": {
                  backgroundColor: "#ffffff",
                  color: "#3892ee",
                },
              }}
              onClick={() => {
                const url = `https://www.google.com/maps?q=${lat},${lng}`;
                window.open(url, "_blank", "noopener,noreferrer");
              }}
            >
              <MapIcon /> &nbsp;Map
            </MDButton>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            xl={4}
            sx={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}
          >
            <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
            <img
            loading="lazy"
              src={cageimg}
              alt="Description"
              style={{ maxWidth: "80%", maxHeight: "200px", border: "2px solid black" }}
            />
            <Divider orientation="vertical" sx={{ mx: 0 }} />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Bill
Bill.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
Bill.propTypes = {
  name: PropTypes.string.isRequired,
  uname: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  vat: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
  time: PropTypes.string.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  cageimg: PropTypes.string.isRequired,
  csrno: PropTypes.string.isRequired,
};

export default Bill;
