import { forwardRef } from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNavigate } from 'react-router-dom';
import menuItem from "examples/Items/NotificationItem/styles";

// Functional component that uses useNavigate
const NotificationItem = forwardRef(({ icon, title, ...rest }, ref) => {
 
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('authToken'); 
    console.log('Logged out successfully');
       navigate('/authentication/sign-in'); 
  };

  return (
    <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)}>
      <MDBox component={Link} py={0.5} display="flex" alignItems="center" lineHeight={1} onClick={handleLogout}>
        <MDTypography variant="body1" color="secondary" lineHeight={0.75} onClick={handleLogout}>
          {icon}
        </MDTypography>
        <MDTypography variant="button" fontWeight="regular" sx={{ ml: 1 }} onClick={handleLogout}>
          {title}
        </MDTypography>
      </MDBox>
    </MenuItem>
  );
});

// Typechecking props for the NotificationItem
NotificationItem.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default NotificationItem;
