/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import { TextField, Button, Container, Typography, Select, MenuItem, InputLabel } from '@mui/material';

// Billing page components
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import Invoices from "layouts/billing/components/Invoices";
import BillingInformation from "layouts/billing/components/BillingInformation";
import Transactions from "layouts/billing/components/Transactions";
import DataTable from "examples/Tables/DataTable";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import projectsTableData from "layouts/tables/data/projectsTableData";
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { SelectChangeEvent } from '@mui/material/Select';
import axios from "axios"; // Import Axios
import { useNavigate } from "react-router-dom";
import { baseurl } from "domain";
import AddCageEx from "components/module";
// import Loading from "components/Loading";

function Billing() {
  const { columns, rows } = projectsTableData();
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(true); // Loading state
  const [formValues, setFormValues] = useState({
    srNo: "",
    status: "",
    locName: "",
    region: "",
    lat: "",
    lng: ""
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formValues);

    try {
      const response = await axios.post(`https://${baseurl}/addCage`, formValues);
      console.log('Cage submitted successfully:', response.data);
      alert("Cage added successfully!");
      navigate('/Cages');
    } catch (error) {
      alert("Error Adding Cage");
      console.error('Error submitting question:', error);
    }
    finally{
      // setLoading(false);
    }
  };
  // if (loading) {
  //   return <Loading />; // Show loading component if data is still loading
  // }

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  

  const handleClick = () => {
    alert('Button clicked!');
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                  <Grid item>
                    <MDTypography variant="h6" color="white">
                      Add Cages
                    </MDTypography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="white"
                      onClick={handleOpen}
                      style={{ backgroundColor: 'white', color: '#0076CE' }}
                    >
                      + Import Bulk Cage
                    </Button>
                    <AddCageEx
        open={open}
        onClose={handleClose}
       
        
      />
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>

                <Container maxWidth="sm">

                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Serial No"
                          name="srNo"
                          value={formValues.srNo}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel>Status</InputLabel>
                            <Select
                              name="status"
                              value={formValues.status}
                              label="Status"
                              onChange={handleChange}
                              style={{ padding:12 }}
                            >
                              <MenuItem value={"active"}>Active</MenuItem>
                              <MenuItem value={"camp-cage"}>Camp Cage</MenuItem>
                              <MenuItem value={"maintenance"}>Maintenance</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Location name"
                          name="locName"
                          value={formValues.locName}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel>Region</InputLabel>
                            <Select
                              name="region"
                              value={formValues.region}
                              label="Region"
                              onChange={handleChange}
                              style={{ padding: 12 }}
                            >
                              <MenuItem value={"Junnar"}>Junnar</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel>Range</InputLabel>
                            <Select
                              name="range"
                              value={formValues.range}
                              label="Range"
                              onChange={handleChange}
                              style={{ padding: 12 }}
                            >
                              <MenuItem value={"Junnar"}>Junnar</MenuItem>
                              <MenuItem value={"Ghodegaon"}>Ghodegaon</MenuItem>
                              <MenuItem value={"Chakan"}>Chakan</MenuItem>
                              <MenuItem value={"Shirur"}>Shirur</MenuItem>
                              <MenuItem value={"Otur"}>Otur</MenuItem>
                              <MenuItem value={"Khed"}>Khed</MenuItem>
                              <MenuItem value={"Manchar"}>Manchar</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="body2" marginLeft={0.5}>
                          Location Co-ordinates
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Latitude"
                          name="lat"
                          value={formValues.lat}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Longitude"
                          name="lng"
                          value={formValues.lng}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} paddingBottom={4}>

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                          style={{ color: 'white' }}

                        >
                          Add Cage
                        </Button>
                      </Grid>

                    </Grid>
                  </form>
                </Container>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
            </Card>
          </Grid>

        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Billing;
