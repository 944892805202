import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpg";
import brand1 from "assets/images/logo2.png";
import axios from "axios";
import { baseurl } from "domain";

function Basic() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      console.log("Token found in localStorage:", token); // Log the token
      setIsAuthenticated(true);
      navigate("/dashboard"); // Redirect to the dashboard or another page
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const token = localStorage.getItem("authToken");
    if (token) {
      setError("Already logged in."); // Set error message if token is present
      console.error("Login attempt detected with existing token."); // Log the error
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`https://${baseurl}/loginAdmin`, {
        username,
        password,
      });

      const { token, uid, designation, name, range } = response.data;
      console.log("Received token:", token); // Log the token received from the API
      localStorage.setItem("authToken", token);
      localStorage.setItem("designation", designation);
      localStorage.setItem("uid", uid);
      localStorage.setItem("name", name);
      localStorage.setItem("range", range);
      setIsAuthenticated(true); // Set authenticated status
      console.log("Login successful");
      navigate("/dashboard"); // Redirect to the dashboard or another page
    } catch (error) {
      console.error("Login error:", error); // Log the error if login fails
      setError("Login failed. Please check your credentials.");
    } finally {
      setLoading(false);
    }
  };

  if (isAuthenticated) {
    return null; // Or redirect to another page if already authenticated
  }

  return (
    <BasicLayout image={bgImage}>
      <MDTypography
        variant="h4"
        fontWeight="medium"
        color="white"
        mb={2}
        sx={{
          position: "absolute",
          top: "10%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 10,
          textAlign: "center",
          fontSize: {
            xs: "1.5rem",
            // sm: "1.5rem",
            // md: "2.5rem",
            // lg: "3rem",
            // xl: "3.5rem",
          },
        }}
      >
        Cage Management System
      </MDTypography>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mb={2}>
            Sign in
          </MDTypography>
          <MDBox
            component="img"
            src={brand1}
            alt="Brand"
            sx={{
              width: "100%",
              maxWidth: "20rem",
              height: "auto",
              objectFit: "contain",
            }}
          />
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleLogin}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Username"
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
                required
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                required
              />
            </MDBox>
            {error && (
              <MDBox mb={2}>
                <MDTypography variant="body2" color="error">
                  {error}
                </MDTypography>
              </MDBox>
            )}
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={loading}>
                {loading ? "Signing in..." : "Sign in"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
