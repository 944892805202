// /**
// =========================================================
// * Material Dashboard 2 React - v2.2.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/material-dashboard-react
// * Copyright 2023 Creative Tim (https://www.creative-tim.com)

// Coded by www.creative-tim.com

//  =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
// */

// // prop-types is a library for typechecking of props
// import PropTypes from "prop-types";

// // @mui material components
// import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
// import Icon from "@mui/material/Icon";
// import { Link } from "react-router-dom";
// // Material Dashboard 2 React components
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// function ComplexStatisticsCard({ color, title, count, percentage, icon, link }) {
//   return (
//     <Link to={link} style={{ textDecoration: "none" }}>
//       <Card>
//         <MDBox
//           variant="gradient"
//           bgColor={color}
//           color={color === "light" ? "dark" : "white"}
//           coloredShadow={color}
//           borderRadius="xl"
//         >
//           <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
//             <MDBox
//               variant="gradient"
//               bgColor={color}
//               color={color === "light" ? "dark" : "white"}
//               coloredShadow={color}
//               borderRadius="xl"
//               display="flex"
//               justifyContent="center"
//               alignItems="center"
//               width="4rem"
//               height="4rem"
//               mt={-3}
//             >
//               <Icon fontSize="medium" color="inherit">
//                 {icon}
//               </Icon>
//             </MDBox>
//             <MDBox textAlign="right" lineHeight={1.25}>
//               <MDTypography variant="button" fontWeight="light" color="white">
//                 {title}
//               </MDTypography>
//               <MDTypography variant="h4" color="white">
//                 {count}
//               </MDTypography>
//             </MDBox>
//           </MDBox>
//           <Divider />
//           {/*<MDBox pb={2} px={2}>
//           <MDTypography component="p" variant="button" color="text" display="flex">
//             <MDTypography
//               component="span"
//               variant="button"
//               fontWeight="bold"
//               color={percentage.color}
//             >
//               {percentage.amount}
//             </MDTypography>
//             &nbsp;{percentage.label}
//           </MDTypography>
//         </MDBox> */}
//         </MDBox>
//       </Card>
//     </Link>
//   );
// }

// // Setting default values for the props of ComplexStatisticsCard
// ComplexStatisticsCard.defaultProps = {
//   color: "info",
//   percentage: {
//     color: "success",
//     text: "",
//     label: "",
//   },
// };

// // Typechecking props for the ComplexStatisticsCard
// ComplexStatisticsCard.propTypes = {
//   color: PropTypes.oneOf([
//     "primary",
//     "secondary",
//     "info",
//     "success",
//     "warning",
//     "error",
//     "light",
//     "dark",
//   ]),
//   title: PropTypes.string.isRequired,
//   count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
//   percentage: PropTypes.shape({
//     color: PropTypes.oneOf([
//       "primary",
//       "secondary",
//       "info",
//       "success",
//       "warning",
//       "error",
//       "dark",
//       "white",
//     ]),
//     amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//     label: PropTypes.string,
//   }),
//   icon: PropTypes.node.isRequired,
//   link: PropTypes.string.isRequired,
// };

// export default ComplexStatisticsCard;

import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { Link } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function ComplexStatisticsCard({ color, title, count, percentage, icon, link }) {
  return (
    <Link to={link} style={{ textDecoration: "none" }}>
      <Card>
        <MDBox
          display="flex"
          justifyContent="space-between"
          pt={1}
          px={2}
          pb={2}
          variant="gradient"
          bgColor={`${color}1`}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
        >
          <MDBox
            variant="gradient"
            bgColor={color}
            color={color === "light" ? "dark" : "white"}
            coloredShadow={color}
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4.5rem"
            height="4.5rem"
            mt={-3}
            // No opacity change here, it remains as is
          >
            <Icon fontSize="large" color="inherit">
              {icon}
            </Icon>
          </MDBox>
          <MDBox textAlign="right" lineHeight={1.25}>
            <MDTypography variant="button" fontSize="1.65rem" fontWeight="regular" color="white">
              {title}
            </MDTypography>
            <MDTypography variant="h2" color="white">
              {count}
            </MDTypography>
          </MDBox>
        </MDBox>

        {/*<MDBox pb={2} px={2}>
          <MDTypography component="p" variant="button" color="text" display="flex">
            <MDTypography
              component="span"
              variant="button"
              fontWeight="bold"
              color={percentage.color}
            >
              {percentage.amount}
            </MDTypography>
            &nbsp;{percentage.label}
          </MDTypography>
        </MDBox> */}
      </Card>
    </Link>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
};

export default ComplexStatisticsCard;
