/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios"; // Import Axios
import { baseurl } from "domain";
import MapIcon from "@mui/icons-material/Map";

export default function data() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://${baseurl}/getAllCages`);
        setData(response.data.cages);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const handleClickView = (cid) => {
    navigate(`/CageProfile?cid=${cid}`);
  };

  const handleClickDelete = async (cid) => {
    if (window.confirm("Do you really want to delete this Cage ?")) {
      try {
        const response = await axios.delete(`https://${baseurl}/deleteCage?cid=${cid}`);
        if (response.status === 200) {
          alert("Cage Deleted Successfully!");
          setData((prevData) => prevData.filter((item) => item.cid !== cid));
        } else {
          alert("Try Again");
        }
      } catch (error) {
        alert("Error Deleting Cage");
        console.error("Error deleting data:", error);
      }
    }
  };

  const handleEditClick = (cid) => {
    navigate(`/CageProfileEdit?cid=${cid}`);
  };

  const Author = ({ name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  if (localStorage.getItem("designation") != "Forester"){
  var columns = [
    { Header: "Sr.No", accessor: "srNo", align: "center" },
    // { Header: "Current Location", accessor: "Id", align: "left" },
    { Header: "status", accessor: "status", align: "center" },
    { Header: "Location", accessor: "Location", align: "center" },
    // { Header: "Region", accessor: "Region", align: "center" },
    { Header: "Range", accessor: "Range", align: "center" },

    { Header: "Map", accessor: "curr_loc", align: "center" },
    { Header: "Edit", accessor: "Edit", align: "center" },
    { Header: "View", accessor: "View", align: "center" },
    { Header: "Delete", accessor: "Delete", align: "center" },
  ];}
  else{
    var columns = [
      { Header: "Sr.no", accessor: "srNo", align: "center" },
      // { Header: "Current Location", accessor: "Id", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "Location", accessor: "Location", align: "center" },
      // { Header: "Region", accessor: "Region", align: "center" },
      { Header: "Range", accessor: "Range", align: "center" },
  
      { Header: "Map", accessor: "curr_loc", align: "center" },
      // { Header: "Edit", accessor: "Edit", align: "center" },
      { Header: "View", accessor: "View", align: "center" },
      // { Header: "Delete", accessor: "Delete", align: "center" },
    ];
  }

  const rows = data.map((cage) => ({
    srNo: <Author name={cage.srNo} email={cage.email} />,
    status: <Job title={cage.id} />,
    status: (
      <MDBox ml={-1}>
        {cage.status == "active" && (
          <MDBadge badgeContent={cage.status} color="success" variant="gradient" size="sm" />
        )}
        {cage.status == "camp-cage" && (
          <MDBadge badgeContent={cage.status} color="error" variant="gradient" size="sm" />
        )}
        {cage.status == "maintenance" && (
          <MDBadge badgeContent={cage.status} color="warning" variant="gradient" size="sm" />
        )}
      </MDBox>
    ),
    Location: <Job title={cage.locName} />,
    Region: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {cage.region}
      </MDTypography>
    ),
    Range: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {cage.range}
      </MDTypography>
    ),
    View: (
      <MDButton
        variant="text"
        color="primary"
        style={{ color: "blue" }}
        onClick={() => handleClickView(cage.cid)}
      >
        <VisibilityIcon style={{ color: "blue" }} />
        &nbsp;view
      </MDButton>
    ),
    curr_loc: (
      <MDButton
        variant="text"
        color="error"
        onClick={() => {
          const url = `https://www.google.com/maps?q=${cage.lat},${cage.lng}`;
          window.open(url, "_blank", "noopener,noreferrer");
        }}
      >
        <MapIcon /> &nbsp;Map
      </MDButton>
    ),
    Delete: (
      <MDButton variant="text" color="error" onClick={() => handleClickDelete(cage.cid)}>
        <Icon>delete</Icon>&nbsp;delete
      </MDButton>
    ),
    Edit: (
      <MDButton variant="text" color="dark" onClick={() => handleEditClick(cage.cid)}>
        <Icon>edit</Icon>&nbsp;edit
      </MDButton>
    ),
  }));

  return { columns, rows };
}
