import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import axios from 'axios';
import { baseurl } from 'domain';

const AddUserEx = ({ open, onClose, actions }) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setFile(file);
  };

  const handleFileSubmit = async () => {
    if (!file) {
      setError('No file uploaded');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(`https://${baseurl}/bulkUserImport`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('File uploaded successfully', response.data);
      alert('Importing process......');
      onClose(); // Optionally close the dialog on successful upload
    } catch (error) {
      setError('Error uploading file. The backend may be missing required libraries. Please ensure the file format is supported or contact support.');
      console.error('Error uploading file', error);
      alert('Error uploading file', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add User</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {actions ? (
          actions
        ) : (
          <>
            <Button onClick={onClose}>Close</Button>
            <Button onClick={handleFileSubmit}>Submit</Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

AddUserEx.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  actions: PropTypes.node,
};

export default AddUserEx;
