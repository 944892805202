import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { TextField, Button, Container, Typography, Select, MenuItem, InputLabel, Grid, Box, List, ListItem, ListItemText, IconButton, Autocomplete } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControl from '@mui/material/FormControl';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { baseurl } from "domain";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function PlatformSettings() {
  const [formValues, setFormValues] = useState({});
  const queryParams = new URLSearchParams(location.search);
  const uid = queryParams.get("uid");

  const [items, setItems] = useState([]); // Array of cids
  const [newItem, setNewItem] = useState(null); // Selected cage object
  const [showInput, setShowInput] = useState(false);

  const [cages, setCages] = useState([]); // Array of cage objects
  const [allCages, setAllCages] = useState([]); // Array of cage objects

  useEffect(() => {
    // Fetch cages from the API
    const fetchCages = async () => {
      try {
        const response = await axios.get(`https://${baseurl}/getAllCampCages`);
        setCages(response.data.cages);
      } catch (error) {
        console.error('Error fetching cages:', error);
      }
    };

    fetchCages();
  }, []);

  useEffect(() => {
    // Fetch cages from the API
    const fetchCages = async () => {
      try {
        const response = await axios.get(`https://${baseurl}/getAllCages`);
        setAllCages(response.data.cages);
      } catch (error) {
        console.error('Error fetching cages:', error);
      }
    };

    fetchCages();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://${baseurl}/getUser?uid=${uid}`);
        const user = { ...response.data.user, password: '' };
        setFormValues(user);
        setFormValues((prevValues) => ({
          ...prevValues,
          editBy: localStorage.getItem("name"), // or any other value you want to set for editBy
        }));
        setItems(response.data.user.cagesAssigned); // Initialize with cids
        console.log("CID - 1 : ", items)
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [uid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleAddItem = () => {
    if (newItem) {
      const updatedItems = [...formValues.cagesAssigned, newItem.cid]; // Store only cid
      setFormValues({
        ...formValues,
        cagesAssigned: updatedItems,
      });
      setItems(updatedItems); // Update items state
      setNewItem(null); // Clear selection after adding
      setShowInput(false); // Hide input after adding
    } else {
      alert('Please select a cage to add.');
    }
  };

  const handleDeleteItem = (cid) => {
    const updatedItems = items.filter(item => item !== cid); // Remove by cid
    setItems(updatedItems);
    setFormValues({
      ...formValues,
      cagesAssigned: updatedItems,
    });
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formValues);

    setFormValues((prevValues) => ({
      ...prevValues,
      editBy: localStorage.getItem("name"), // or any other value you want to set for editBy
    }));

    try {
      const response = await axios.put(`https://${baseurl}/editUser`, formValues);
      console.log('User Updated successfully:', response.data);
      alert("User Updated successfully!");
      navigate('/Users');
    } catch (error) {
      alert("Error updating user");
      console.error('Error submitting question:', error);
    }
  };

  // Function to get cage object by cid
  const getCageByCid = (cid) => allCages.find(cage => cage.cid === cid);

  return (
    <Card sx={{ boxShadow: "none" }}>

      <Grid container spacing={8} display="flex" justifyContent="center">
        <Grid item xs={12} md={6} xl={7} >
          <MDBox p={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Profile Edit
            </MDTypography>
          </MDBox>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="First Name"
                  name="firstName"
                  value={formValues.firstName || ''}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  value={formValues.lastName || ''}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  value={formValues.email || ''}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Phone"
                  name="phone"
                  value={formValues.phone || ''}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="text"
                  label="ID"
                  name="id"
                  value={formValues.id || ''}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Designation</InputLabel>
                    <Select
                      name="designation"
                      value={formValues.designation || ''}
                      label="Designation"
                      onChange={handleChange}
                      style={{ padding: 12 }}
                    >
                      <MenuItem value={"DyCF"}>DyCF</MenuItem>
                      <MenuItem value={"ACF"}>ACF</MenuItem>
                      <MenuItem value={"RFO"}>RFO</MenuItem>
                      <MenuItem value={"Forester"}>Forester</MenuItem>
                      <MenuItem value={"Forest Guard"}>Forest Guard</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Region</InputLabel>
                    <Select
                      name="region"
                      value={formValues.region || ''}
                      label="Region"
                      onChange={handleChange}
                      style={{ padding: 12 }}
                    >
                      <MenuItem value={"Junnar"}>Junnar</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Range</InputLabel>
                    <Select
                      name="range"
                      value={formValues.range || ''}
                      label="Range"
                      onChange={handleChange}
                      style={{ padding: 12 }}
                    >
                      <MenuItem value={"Junnar"}>Junnar</MenuItem>
                      <MenuItem value={"Ghodegaon"}>Ghodegaon</MenuItem>
                      <MenuItem value={"Chakan"}>Chakan</MenuItem>
                      <MenuItem value={"Shirur"}>Shirur</MenuItem>
                      <MenuItem value={"Otur"}>Otur</MenuItem>
                      <MenuItem value={"Khed"}>Khed</MenuItem>
                      <MenuItem value={"Manchar"}>Manchar</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Username"
                  name="username"
                  value={formValues.username || ''}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>

            </Grid>
          </form>   </Grid>

        <Grid item xs={12} md={6} xl={4} style={{ marginTop: "32px" }} >
          <Card sx={{ boxShadow: 3, borderRadius: '10px' }}>
            <MDBox p={2}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Assigned Cages
              </MDTypography>
            </MDBox>
            <Container maxWidth="sm">
              <List>
                {items.map((cid, index) => {
                  const cage = getCageByCid(cid); // Get cage object by cid
                  const backgroundColor = index % 2 === 0 ? '#AAFFFC' : '#f0f0f0';
                  return (
                    <ListItem
                      key={cid}
                      sx={{
                        bgcolor: backgroundColor,
                        marginBottom: '8px',
                        borderRadius: '5px',
                        padding:"4px",
                        paddingLeft:"12px",
                        boxShadow: 1,
                      }}
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete" color="error" style={{paddingRight:"26px"}} onClick={() => handleDeleteItem(cid)}>
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: '1rem' }} marginBottom={1} style={{ marginTop: "12px" }}>
                            <b>Sr. No : </b>{cage ? cage.srNo : 'Unknown'} {/* Display cage number */}
                          </Typography>
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>

              <Grid item xs={12} sm={6} marginTop={2}>
                {showInput ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                    <Autocomplete
                      options={cages}
                      getOptionLabel={(option) => option.srNo.toString()}
                      onChange={(event, newValue) => setNewItem(newValue)}
                      style={{ marginRight: "18px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Cage Number"
                          variant="outlined"
                          sx={{ marginRight: '10px', minWidth: '200px' }}
                        />
                      )}
                    />
                    <Button variant="contained" onClick={handleAddItem} style={{ backgroundColor: '#007FFF', color: 'white' }}>
                      Add
                    </Button>
                  </Box>
                ) : (
                  <Button variant="contained" color="primary" style={{ backgroundColor: '#007FFF', color: 'white', marginBottom: "32px", marginTop: "12px" }} onClick={() => setShowInput(true)}>
                    Add Cage
                  </Button>
                )}
              </Grid>
            </Container>
          </Card> </Grid>

        <Grid item xs={12} sm={6} paddingBottom={4}>
          <Box display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              style={{ color: 'white', width: "30%" }}
            >
              Update
            </Button>
          </Box>
        </Grid>


      </Grid>
    </Card>
  );
}

export default PlatformSettings;
