/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Switch from "@mui/material/Switch";
import { Grid, TextField, Button, Container, Typography } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Card from "@mui/material/Card";
import projectsTableData from "layouts/tables/data/projectsTableData";
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { SelectChangeEvent } from '@mui/material/Select';
import axios from "axios"; // Import Axios
import { useNavigate } from "react-router-dom";
import { baseurl } from "domain";
import { Select, MenuItem, InputLabel } from '@mui/material';
// import { useNavigate } from "react-router-dom";

function PlatformSettings() {
  const [followsMe, setFollowsMe] = useState(true);
  const [answersPost, setAnswersPost] = useState(false);
  const [mentionsMe, setMentionsMe] = useState(true);
  const [newLaunches, setNewLaunches] = useState(false);
  const [productUpdate, setProductUpdate] = useState(true);
  const [newsletter, setNewsletter] = useState(false);
  const [formValues, setFormValues] = useState({});

  const [cageData, setCageData] = useState({});

  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  // const question_id = queryParams.get("id");
  const cid = queryParams.get("cid");

  useEffect(() => {
    const fetchCageData = async () => {
      try {
        const response = await axios.get(`https://${baseurl}/getCage?cid=${cid}`);
        setCageData(response.data.cage);
      } catch (error) {
        console.error("Error fetching cage data:", error);
      }
    };

    fetchCageData();
  }, [cid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCageData({
      ...cageData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formValues);

    try {
      const response = await axios.put(`https://${baseurl}/updateCage`, cageData);
      console.log('Cage Updated successfully:', response.data);
      alert("Cage Updated successfully!");
      navigate('/Cages');
    } catch (error) {
      alert("Error updating Cage");
      console.error('Error submitting question:', error);
    }
  };


  const handleClick = () => {
    alert('Button clicked!');
  };
  return (
    <Card sx={{ boxShadow: "none" }}  display="flex" justifyContent="center">
      <MDBox p={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Cage Edit
        </MDTypography>
      </MDBox>
      <Container maxWidth="sm">

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Serial No"
                name="srNo"
                value={cageData.srNo  || ''}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Status</InputLabel>
                  <Select
                    name="status"
                    value={cageData.status  || ''}
                    label="Status"
                    onChange={handleChange}
                    style={{ padding: 12 }}
                  >
                    <MenuItem value={"active"}>Active</MenuItem>
                    <MenuItem value={"camp-cage"}>Camp Cage</MenuItem>
                    <MenuItem value={"maintenance"}>Maintenance</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Location name"
                name="locName"
                value={cageData.locName  || ''}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Region</InputLabel>
                  <Select
                    name="region"
                    value={cageData.region  || ''}
                    label="Region"
                    onChange={handleChange}
                    style={{ padding: 12 }}
                  >
                    <MenuItem value={"Junnar"}>Junnar</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Range</InputLabel>
                  <Select
                    name="range"
                    value={cageData.range  || ''}
                    label="Range"
                    onChange={handleChange}
                    style={{ padding: 12 }}
                  >
                    <MenuItem value={"Junnar"}>Junnar</MenuItem>
                    <MenuItem value={"Ghodegaon"}>Ghodegaon</MenuItem>
                    <MenuItem value={"Chakan"}>Chakan</MenuItem>
                    <MenuItem value={"Shirur"}>Shirur</MenuItem>
                    <MenuItem value={"Otur"}>Otur</MenuItem>
                    <MenuItem value={"Khed"}>Khed</MenuItem>
                    <MenuItem value={"Manchar"}>Manchar</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" marginLeft={0.5}>
                Location Co-ordinates
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Latitude"
                name="lat"
                value={cageData.lat  || ''}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Longitude"
                name="lng"
                value={cageData.lng  || ''}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </form>
        

      </Container>
      <Grid  display="flex" justifyContent="center" marginTop={3}>

<Button
  variant="contained"
  color="primary"
  onClick={handleSubmit}
  style={{ color: 'white' }}

>
  Update Cage
</Button>
</Grid>
    </Card>
  );
}

export default PlatformSettings;
