/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import BillingInformation from "layouts/billing/components/BillingInformation";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { baseurl } from "domain";
import axios from "axios";
// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import Bill from "layouts/billing/components/Bill";
import CageData from "layouts/billing/components/CageActivitiesData";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import { Divider } from "@mui/material";
import TodayIcon from "@mui/icons-material/Today";
import GpsOffIcon from "@mui/icons-material/GpsOff";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import InventoryIcon from "@mui/icons-material/Inventory";
import EngineeringIcon from "@mui/icons-material/Engineering";
import Loading from "components/Loading";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const navigate = useNavigate();

  const [userLogsData, setUserLogsData] = useState([]);
  const [userCountsData, setCountsData] = useState({});
  const [loading, setLoading] = useState(true); // Loading state
  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      navigate("/authentication/sign-in");
    }
  }, [navigate]);
  useEffect(() => {
    const fetchUserLogsData = async () => {
      try {
        const response = await axios.get(`https://${baseurl}/getDashboardLogs`);
        setUserLogsData(response.data.logs);
        setCountsData(response.data.countsValues);
        console.log(userLogsData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchUserLogsData();
  }, []);
  if (loading) {
    return <Loading />; // Show loading component if data is still loading
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="teal"
                icon=<InventoryIcon />
                title="Total Cages"
                count={userCountsData.totalCagesCount}
                link="/Filters"
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon=<OnlinePredictionIcon />
                title="Active Cages"
                count={userCountsData.activeCagesCount}
                link="/Filters?status=active"
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon=<EngineeringIcon />
                title="Maintenance Cages"
                count={userCountsData.maintenanceCagesCount}
                link="/Filters?status=maintenance"
              />
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={4} marginTop={0.5}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="error"
                icon=<GpsOffIcon />
                title="Camp Cages"
                count={userCountsData.campCagesCount}
                link="/Filters?status=camp-cage"
              />
            </MDBox>
          </Grid>

          {/* <Grid item xs={12} md={6} lg={}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Cage"
                count="22"
              
              />
            </MDBox>
          </Grid> */}
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="cyan"
                icon=<TodayIcon />
                title="Today's Cage Activity"
                count={userCountsData.todaysCagesCount}
                link="/cage_activities"
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Forest Team"
                count={userCountsData.totalUsersCount}
                link="/Users"
              />
            </MDBox>
          </Grid>
        </Grid>

        <Divider />

        {/* <Grid item xs={12}>
              <BillingInformation />
             </Grid> */}
        <Card
          sx={{
            position: "relative",
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          {/* <Divider /> */}

          <MDBox pt={3} px={2}>
            <MDTypography variant="h4" fontWeight="medium">
              Recent Activities
            </MDTypography>
          </MDBox>
          <MDBox pt={1} pb={2} px={2}>
            <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
              {userLogsData.map((log, index) => (
                <>
                  {log.lType == "userActivityLog" && (
                    <CageData
                      key={index}
                      name={log.lText}
                      uname={log.name}
                      company={log.loc_name}
                      vat={log.date}
                      time={log.time}
                      lat={log.lat}
                      lng={log.lng}
                      cageimg={log.currentImg}
                      csrno={log.srNo}
                    />
                  )}
                </>
              ))}
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
