import axios from "axios";
import { baseurl } from "domain";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Icon from "@mui/material/Icon";
import team2 from "assets/images/team-2.jpg";
import { useState } from "react";

// Define Author component
const Author = ({ image, name, email }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDAvatar src={image} name={name} size="sm" />
    <MDBox ml={2} lineHeight={1}>
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {name}
      </MDTypography>
      <MDTypography variant="button">{email}</MDTypography>
    </MDBox>
  </MDBox>
);

Author.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

// Define Job component
const Job = ({ title, description }) => (
  <MDBox lineHeight={1} textAlign="left">
    <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
      {title}
    </MDTypography>
    <MDTypography variant="caption">{description}</MDTypography>
  </MDBox>
);

Job.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

Job.defaultProps = {
  description: "",
};

async function authorsTableData(filters, handleClickView, handleClickDelete, handleEditClick) {
  let data = [];

  try {
    const response = await axios.post(`https://${baseurl}/filterUsers`, filters);
    data = response.data.users;
  } catch (error) {
    console.error("Error fetching filtered data:", error);
  }

  if (localStorage.getItem("designation") != "Forester") {
    var columns = [
      { Header: "Name", accessor: "Name", width: "20%", align: "left" },
      { Header: "Username", accessor: "Username", align: "left" },
      { Header: "Designation", accessor: "Designation", align: "center" },
      { Header: "Range", accessor: "Range", align: "center" },
      { Header: "Assigned", accessor: "Cages_Assigned", align: "center" },
      { Header: "Edit", accessor: "Edit", align: "center" },
      { Header: "View", accessor: "View", align: "center" },
      { Header: "Delete", accessor: "Delete", align: "center" },
    ];
  } else {
    var columns = [
      { Header: "Name", accessor: "Name", width: "20%", align: "left" },
      { Header: "Username", accessor: "Username", align: "left" },
      { Header: "Designation", accessor: "Designation", align: "center" },
      { Header: "Range", accessor: "Range", align: "center" },
      { Header: "Cages Assigned", accessor: "Cages_Assigned", align: "center" },
      // { Header: "Edit", accessor: "Edit", align: "center" },
      { Header: "View", accessor: "View", align: "center" },
      // { Header: "Delete", accessor: "Delete", align: "center" },
    ];
  }

  const rows = data
  .filter((user) => user.designation !== "system")
  .map((user) => ({
    Name: <Author image={team2} name={`${user.firstName} ${user.lastName}`} email={user.email} />,
    Username: <Job title={user.username} />,
    Designation: <Job title={user.designation} />,
    Range: <Job title={user.range} />,
    Cages_Assigned: <Job title={user.cagesAssigned.length.toString()} />,
    View: (
      <MDButton
        variant="text"
        color="primary"
        style={{ color: "blue" }}
        onClick={() => handleClickView(user.uid)}
      >
        <VisibilityIcon style={{ color: "blue" }} />
        &nbsp;view
      </MDButton>
    ),
    Delete: (
      <MDButton variant="text" color="error" onClick={() => handleClickDelete(user.uid)}>
        <Icon>delete</Icon>&nbsp;delete
      </MDButton>
    ),
    Edit: (
      <MDButton variant="text" color="dark" onClick={() => handleEditClick(user.uid)}>
        <Icon>edit</Icon>&nbsp;edit
      </MDButton>
    ),
  }));


  return { columns, rows };
}

export default authorsTableData;
