/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import BillingInformation from "layouts/billing/components/BillingInformation";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { baseurl } from "domain";
import axios from "axios";
// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import Bill from "layouts/billing/components/Bill";
import CageData from "layouts/billing/components/CageRejected";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import { Divider } from "@mui/material";

import projectsTableData from "layouts/tables/data/actionTableData";
import DataTable from "examples/Tables/DataTable";
import Loading from "components/Loading";

function Actions() {
  const { sales, tasks } = reportsLineChartData;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Loading state

  const [userLogsData, setUserLogsData] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      navigate("/authentication/sign-in");
    }
  }, [navigate]);
  useEffect(() => {
    const fetchUserLogsData = async () => {
      try {
        const response = await axios.get(`https://${baseurl}/getAllActionsLogs`);
        setUserLogsData(response.data.logs);
        console.log(userLogsData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserLogsData();
  }, []);
  const { columns, rows } = projectsTableData();

  if (loading) {
    return <Loading />; // Show loading component if data is still loading
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <MDBox py={3}>
        <Card
          sx={{
            position: "relative",
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <MDBox pt={3} px={2}>
            <MDTypography variant="h6" fontWeight="medium">
              Actions
            </MDTypography>
          </MDBox>
          <MDBox pt={1} pb={2} px={2} style={{ maxHeight: "400px", overflowY: "auto" }}>
            <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
              {userLogsData.map((log, index) => (
                <>
                  {log.lType == "cageAssignedUser" && (
                    <Bill
                      key={index}
                      name={log.lText}
                      company={log.name}
                      email={log.range}
                      vat={log.date}
                    />
                  )}
                  {log.lType == "cageAssignedRemoveUser" && (
                    <CageData
                      key={index}
                      name={log.lText}
                      company={log.name}
                      email={log.range}
                      vat={log.date}
                    />
                  )}
                </>
              ))}
            </MDBox>
          </MDBox>
        </Card>
      </MDBox> */}
      <MDBox py={3}>
        <Card
          sx={{
            position: "relative",
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <MDBox pt={3} px={2}>
            <MDTypography variant="h6" fontWeight="medium">
              Actions
            </MDTypography>
          </MDBox>
          <MDBox pt={3}>
            <DataTable
              table={{ columns, rows }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Actions;
