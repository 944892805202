import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import InstagramIcon from "@mui/icons-material/Instagram";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import Header from "layouts/profile/components/Header";
import { baseurl } from "domain";
import Bill from "layouts/billing/components/Bill";
import Card from "@mui/material/Card";
import CageData from "layouts/billing/components/CageActivitiesData";
import Loading from "components/Loading";
function Overview() {
  const [userData, setUserData] = useState({});
  const [userLogsData, setUserLogsData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  // const { uid } = useParams(); // Assuming you have uid in your route params

  const queryParams = new URLSearchParams(location.search);
  // const question_id = queryParams.get("id");
  const uid = queryParams.get("uid");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://${baseurl}/getUser?uid=${uid}`);
        setUserData(response.data.user);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [uid]);

  useEffect(() => {
    const fetchUserLogsData = async () => {
      try {
        const response = await axios.get(`https://${baseurl}/getUserLogs?uid=${uid}`);
        setUserLogsData(response.data.logs);
        console.log(userLogsData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserLogsData();
  }, [uid]);

  if (loading) {
    return <Loading />; // Show loading component if data is still loading
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              {/* <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} /> */}
              <ProfileInfoCard
                title="profile information"
                description={userData.description || "No description available."}
                info={{
                  fullName: `${userData.firstName} ${userData.lastName}`,
                  mobile: userData.phone || "N/A",
                  email: userData.email || "N/A",
                  id: userData.id || "N/A",
                  designation: userData.designation || "N/A",
                  region: userData.region || "N/A",
                  range: userData.range || "N/A",
                  username: userData.username || "N/A",
                  // location: userData.location || "N/A",
                }}
                social={[
                  {
                    link: "https://www.facebook.com/CreativeTim/",
                    // icon: <FacebookIcon />,
                    color: "facebook",
                  },
                  {
                    link: "https://twitter.com/creativetim",
                    // icon: <TwitterIcon />,
                    color: "twitter",
                  },
                  {
                    link: "https://www.instagram.com/creativetimofficial/",
                    // icon: <InstagramIcon />,
                    color: "instagram",
                  },
                ]}
                action={{ route: "", tooltip: "" }}
                shadow={false}
              />
              {/* <Divider orientation="vertical" sx={{ mx: 0 }} /> */}
            </Grid>
          </Grid>
        </MDBox>
      </Header>
      <MDBox mb={2} />

      <Card
        sx={{
          position: "relative",
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <MDBox pt={3} px={2}>
          <MDTypography variant="h6" fontWeight="medium">
            Logs
          </MDTypography>
        </MDBox>
        <MDBox pt={1} pb={2} px={2}>
          <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
            {userLogsData.map((log, index) => (
              <>
                {log.lType == "cageAssignedUser" && (
                  <Bill
                    key={index}
                    name={log.lText}
                    company={log.name}
                    email={log.range}
                    vat={log.date}
                  />
                )}

                {log.lType == "userActivityLog" && (
                  <CageData
                    key={index}
                    name={log.lText}
                    uname={log.name}
                    company={log.loc_name}
                    vat={log.date}
                    time={log.time}
                    lat={log.lat}
                    lng={log.lng}
                    cageimg={log.currentImg}
                    csrno={log.srNo}
                  />
                )}
              </>
            ))}
          </MDBox>
        </MDBox>
      </Card>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
