/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import React, { useState } from 'react';
import axios from "axios"; // Import Axios

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import filterTable from "layouts/tables/data/filterTable"
import { Password } from "@mui/icons-material";
import { baseurl } from "domain";
import { useNavigate } from "react-router-dom";

import { TextField, Button, Container, Typography, Select, MenuItem, InputLabel, Grid, Box } from '@mui/material';
// import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import AddUserEx from "components/module2";
// import Loading from "components/Loading";

function Tables() {
  const { columns, rows } = authorsTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();
  // const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    // address: '',
    id: '',
    designation: '',
    region : '',
    range : '',
    username: '',
    password: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formValues);

    const formData = new FormData();


    try {
      const response = await axios.post(`https://${baseurl}/registerUser`, formValues);
      console.log('Question submitted successfully:', response.data);
      alert("User added successfully!");
      navigate('/Users');
    } catch (error) {
      console.error('Error submitting question:', error);
    }
    finally{
      // setLoading(false);
    }
  };
  // if (loading) {
  //   return <Loading />; // Show loading component if data is still loading
  // }
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
 

  const handleClick = () => {
    alert('Button clicked!');
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                  <Grid item>
                    <MDTypography variant="h6" color="white">
                      Add User
                    </MDTypography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="white"
                      onClick={handleOpen}
                      style={{ backgroundColor: 'white', color: '#0076CE' }}
                    >
                      + Import Bulk User
                    </Button>
                    <AddUserEx
        open={open}
        onClose={handleClose}
        
      />
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>

                <Container maxWidth="sm">
                  <Typography variant="h4" component="h1" gutterBottom>
                    User Details Form
                  </Typography>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="First Name"
                          name="firstName"
                          value={formValues.firstName}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Last Name"
                          name="lastName"
                          value={formValues.lastName}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Email"
                          name="email"
                          value={formValues.email}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Phone"
                          name="phone"
                          value={formValues.phone}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      {/* <Grid item xs={12}>
            <TextField
              fullWidth
              label="Cages Assigned"
              name="address"
              value={formValues.address}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid> */}
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="ID"
                          name="id"
                          value={formValues.id}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel>Desgination</InputLabel>
                            <Select
                              name="designation"
                              value={formValues.designation}
                              label="Designation"
                              onChange={handleChange}
                              style={{ padding: 12 }}
                            >
                              <MenuItem value={"DyCF"}>DyCF</MenuItem>
                              <MenuItem value={"ACF"}>ACF</MenuItem>
                              <MenuItem value={"RFO"}>RFO</MenuItem>
                              <MenuItem value={"Forester"}>Forester</MenuItem>
                              <MenuItem value={"Forest Guard"}>Forest Guard</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel>Region</InputLabel>
                            <Select
                              name="region"
                              value={formValues.region}
                              label="Region"
                              onChange={handleChange}
                              style={{ padding: 12 }}
                            >
                              <MenuItem value={"Junnar"}>Junnar</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel>Range</InputLabel>
                            <Select
                              name="range"
                              value={formValues.range}
                              label="Range"
                              onChange={handleChange}
                              style={{ padding: 12 }}
                            >
                              <MenuItem value={"Junnar"}>Junnar</MenuItem>
                              <MenuItem value={"Ghodegaon"}>Ghodegaon</MenuItem>
                              <MenuItem value={"Chakan"}>Chakan</MenuItem>
                              <MenuItem value={"Shirur"}>Shirur</MenuItem>
                              <MenuItem value={"Otur"}>Otur</MenuItem>
                              <MenuItem value={"Khed"}>Khed</MenuItem>
                              <MenuItem value={"Manchar"}>Manchar</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Username"
                          name="username"
                          value={formValues.username}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Password"
                          name="password"
                          value={formValues.password}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xl={12} sl={"auto"} paddingBottom={4}>
                        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            style={{ color: 'white' }}
                          >
                            Add User
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                </Container>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12}>

          </Grid>

        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
