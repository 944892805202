// import React, { useState, useEffect, useRef } from "react";
// import PropTypes from "prop-types";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
// import { Box, Typography, Button } from "@mui/material";
// import ThumbUpIcon from "@mui/icons-material/ThumbUp";
// import ThumbDownIcon from "@mui/icons-material/ThumbDown";
// import axios from "axios";
// import { baseurl } from "domain"; // Make sure this import is correct and matches your setup
// import MDBox from "components/MDBox";

// import Grid from "@mui/material/Grid";
// import { useNavigate } from "react-router-dom";
// const CustomPopup = ({ open, onClose }) => {
//   const [texts, setTexts] = useState([]);
//   const intervalRef = useRef(null);

//   const fetchNotifications = async () => {
//     try {
//       const response = await axios.get(`https://${baseurl}/getAllNotifications`);
//       setTexts(response.data.notifications);
//       if (response.data.notifications.length === 0 && intervalRef.current) {
//         clearInterval(intervalRef.current);
//         intervalRef.current = null;
//       }
//     } catch (error) {
//       console.error("Error fetching notifications:", error);
//     }
//   };

//   const startPolling = () => {
//     if (!intervalRef.current) {
//       intervalRef.current = setInterval(fetchNotifications, 60000); // Poll every 60 seconds
//     }
//   };
//   const navigate = useNavigate();
//   useEffect(() => {
//     if (open) {
//       fetchNotifications();
//       startPolling();
//     }
//     return () => clearInterval(intervalRef.current);
//   }, [open]);

//   const handleStatusUpdate = async (nid, status) => {
//     const name = localStorage.getItem("name");
//     try {
//       await axios.get(
//         `https://${baseurl}/updateNotificationStatus?nid=${nid}&status=${status}&actionBy=${name}`
//       );
//       setTexts((prevTexts) => prevTexts.filter((text) => text.nid !== nid));
//       if (texts.length === 1) {
//         // Means after updating, texts will be empty
//         clearInterval(intervalRef.current);
//         intervalRef.current = null;
//       }
//     } catch (error) {
//       console.error("Error updating notification status:", error);
//     }
//   };

//   return (
//     <Dialog open={open} onClose={onClose}>
//       <DialogTitle>Notifications</DialogTitle>
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           borderColor: "grey.300",
//           width: "100%",
//           padding: 3,
//         }}
//       >
//         {texts.map((text, index) => (
//           <Box
//             key={index}
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//               borderBottom: "1px solid",
//               marginBottom: 2,
//               borderColor: "grey.300",
//               padding: 1,
//             }}
//           >
//             <Typography
//               variant="body2"
//               component="div"
//               marginRight={2}
//               sx={{ color: "black.500", fontSize: "1.3rem", fontWeight: "regular" }}
//             >
//               {text.assignmentText}
//             </Typography>
//             <Box sx={{ display: "flex", alignItems: "center" }}>
//               <Button
//                 variant="contained"
//                 color="success"
//                 style={{ backgroundColor: "#018749", color: "white" }}
//                 onClick={() => handleStatusUpdate(text.nid, "accept")}
//                 sx={{ marginRight: 1 }}
//               >
//                 {/* <ThumbUpIcon color='success' /> */}
//                 Approve
//               </Button>
//               <Button
//                 variant="contained"
//                 color="error"
//                 style={{ backgroundColor: "red", color: "white" }}
//                 onClick={() => handleStatusUpdate(text.nid, "reject")}
//               >
//                 {/* <ThumbDownIcon color='error' /> */}
//                 Reject
//               </Button>
//             </Box>
//           </Box>
//         ))}
//         {texts.length === 0 ? (
//           <>
//             <Typography
//               variant="body2"
//               component="div"
//               marginRight={2}
//               sx={{ color: "black.500", fontSize: "0.875rem", fontWeight: "bold" }}
//             >
//               No More New Notifications To Show
//             </Typography>

//             <br />
//           </>
//         ) : null}
//       </Box>
//       <Grid container spacing={2} alignItems="center" justifyContent="space-between">
//         <DialogActions>
//           <Button
//             onClick={() => {
//               navigate("/Actions");
//             }}
//           >
//             Previous Actions
//           </Button>
//         </DialogActions>
//         <DialogActions>
//           <Button onClick={onClose}>Close</Button>
//         </DialogActions>
//       </Grid>
//     </Dialog>
//   );
// };

// CustomPopup.propTypes = {
//   open: PropTypes.bool.isRequired,
//   onClose: PropTypes.func.isRequired,
// };

// export default CustomPopup;

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import axios from "axios";
import { baseurl } from "domain"; // Make sure this import is correct and matches your setup
import MDBox from "components/MDBox";

import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

const CustomPopup = ({ open, onClose }) => {
  const [texts, setTexts] = useState([]);
  const intervalRef = useRef(null);
  const [loading, setLoading] = useState({});

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`https://${baseurl}/getAllNotifications`);
      setTexts(response.data.notifications);
      if (response.data.notifications.length === 0 && intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const startPolling = () => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(fetchNotifications, 60000); // Poll every 60 seconds
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (open) {
      fetchNotifications();
      startPolling();
    }
    return () => clearInterval(intervalRef.current);
  }, [open]);

  const handleStatusUpdate = async (nid, status) => {
    setLoading((prev) => ({ ...prev, [`${nid}-${status}`]: true }));
    const name = localStorage.getItem("name");
    try {
      await axios.get(
        `https://${baseurl}/updateNotificationStatus?nid=${nid}&status=${status}&actionBy=${name}`
      );
      setTexts((prevTexts) => prevTexts.filter((text) => text.nid !== nid));
      if (texts.length === 1) {
        // Means after updating, texts will be empty
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    } catch (error) {
      console.error("Error updating notification status:", error);
    } finally {
      setLoading((prev) => ({ ...prev, [`${nid}-${status}`]: false }));
    }
  };

  const handleStatusUpdate2 = async (nid, status) => {
    setLoading((prev) => ({ ...prev, [`${nid}-${status}`]: true }));
    const name = localStorage.getItem("name");
    try {
      await axios.get(
        `https://${baseurl}/updateNotificationReqStatus?nid=${nid}&status=${status}&actionBy=${name}`
      );
      setTexts((prevTexts) => prevTexts.filter((text) => text.nid !== nid));
      if (texts.length === 1) {
        // Means after updating, texts will be empty
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    } catch (error) {
      console.error("Error updating notification status:", error);
    } finally {
      setLoading((prev) => ({ ...prev, [`${nid}-${status}`]: false }));
    }
  };

  const reframeText = (assignmentText) => {
    // Regex to match "Assigned Cages" case
    const assignedRegex = /Assigned Cages of (.+?) (.+?) (.+?) are updated/;
    const assignedMatch = assignmentText.match(assignedRegex);

    if (assignedMatch) {
      const designation = assignedMatch[1];
      const firstName = assignedMatch[2];
      const lastName = assignedMatch[3];
      const editBy = assignmentText.match(/by (.+?) to Serial/)[1];
      const newAssigned = assignmentText.match(/Serial Number - (.+?)\./)[1];

      return (
        <span>
          Confirm: Cages Assigned to
          <span style={{ color: "blue" }}>
            {" "}
            {designation} {firstName} {lastName}
          </span>{" "}
          are updated by
          <span style={{ color: "red" }}> {editBy} </span>
          to Serial Number -<span style={{ color: "green" }}> {newAssigned} </span>.
        </span>
      );
    }

    // Regex to match "No Cages Assigned" case
    const noCagesRegex = /No Cages Assigned to (.+?) (.+?) (.+?)\./;
    const noCagesMatch = assignmentText.match(noCagesRegex);

    if (noCagesMatch) {
      const designation = noCagesMatch[1];
      const firstName = noCagesMatch[2];
      const lastName = noCagesMatch[3];

      return (
        <span>
          Confirm: No Cages Assigned to
          <span style={{ color: "blue" }}>
            {" "}
            {designation} {firstName} {lastName}
          </span>
          .
        </span>
      );
    }

    // Return the original text if it doesn't match either case
    return assignmentText;
  };

  const reframeText2 = (text) => {
    return (
      <span>
        <span style={{ color: "blue" }}>
          {" "}
          {text.designation} {text.name}
        </span>{" "}
        from Range
        <span style={{ color: "red" }}> {text.range} </span>
        Requested for Cages no : <span style={{ color: "green" }}> {text.cageText} </span> Do you
        want to assign it to them ?
      </span>
    );
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Notifications</DialogTitle>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          borderColor: "grey.300",
          width: "100%",
          padding: 3,
        }}
      >
        {texts.map((text, index) => (
          <>
            {text.reqText &&
              ((localStorage.getItem("designation") === "RFO" &&
                localStorage.getItem("range") === text.range) ||
                localStorage.getItem("designation") === "ACF" ||
                localStorage.getItem("designation") === "DyCF") && (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid",
                    //marginBottom: 2,
                    borderColor: "grey.300",
                    padding: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    component="div"
                    marginRight={2}
                    sx={{ color: "black.500", fontSize: "1.3rem", fontWeight: "bold" }}
                  >
                    {reframeText2(text)}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Button
                      variant="contained"
                      color="success"
                      style={{ backgroundColor: "#018749", color: "white" }}
                      onClick={() => handleStatusUpdate2(text.nid, "accept")}
                      disabled={loading[`${text.nid}-accept`]}
                      sx={{ marginRight: 1 }}
                    >
                      {loading[`${text.nid}-accept`] ? <CircularProgress size={24} /> : "Approve"}
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      style={{ backgroundColor: "red", color: "white" }}
                      onClick={() => handleStatusUpdate2(text.nid, "reject")}
                      disabled={loading[`${text.nid}-reject`]} // Disable button while loading
                    >
                      {loading[`${text.nid}-reject`] ? <CircularProgress size={24} /> : "Reject"}
                    </Button>
                  </Box>
                </Box>
              )}

            {text.assignmentText &&
              ((localStorage.getItem("designation") === "RFO" &&
                localStorage.getItem("range") === text.range) ||
                localStorage.getItem("designation") === "ACF" ||
                localStorage.getItem("designation") === "DyCF") && (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid",
                    //marginBottom: 2,
                    borderColor: "grey.300",
                    padding: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    component="div"
                    marginRight={2}
                    sx={{ color: "black.500", fontSize: "1.3rem", fontWeight: "bold" }}
                  >
                    {reframeText(text.assignmentText)}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Button
                      variant="contained"
                      color="success"
                      style={{ backgroundColor: "#018749", color: "white" }}
                      onClick={() => handleStatusUpdate(text.nid, "accept")}
                      disabled={loading[`${text.nid}-accept`]}
                      sx={{ marginRight: 1 }}
                    >
                      {loading[`${text.nid}-accept`] ? <CircularProgress size={24} /> : "Approve"}
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      style={{ backgroundColor: "red", color: "white" }}
                      onClick={() => handleStatusUpdate(text.nid, "reject")}
                      disabled={loading[`${text.nid}-reject`]} // Disable button while loading
                    >
                      {loading[`${text.nid}-reject`] ? <CircularProgress size={24} /> : "Reject"}
                    </Button>
                  </Box>
                </Box>
              )}
            {/* </Box> */}
          </>
        ))}

        {localStorage.getItem("designation") === "RFO" &&
          texts.every((item) => item.range !== localStorage.getItem("range")) && (
            <>
              <Typography
                variant="body2"
                component="div"
                marginRight={2}
                sx={{ color: "black.500", fontSize: "0.875rem", fontWeight: "bold" }}
              >
                No More New Notifications To Show
              </Typography>

              <br />
            </>
          )}

        {texts.length === 0 && localStorage.getItem("designation") !== "RFO" ? (
          <>
            <Typography
              variant="body2"
              component="div"
              marginRight={2}
              sx={{ color: "black.500", fontSize: "0.875rem", fontWeight: "bold" }}
            >
              No More New Notifications To Show
            </Typography>

            <br />
          </>
        ) : null}
      </Box>
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <DialogActions>
          <Button
            onClick={() => {
              navigate("/Actions");
            }}
          >
            Previous Actions
          </Button>
        </DialogActions>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

CustomPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CustomPopup;
